<script src="//editor.unlayer.com/embed.js"></script>

<div class="campaign-container">

    <form [formGroup]="campaignForm" (ngSubmit)="onSubmit()" >
   	<mat-card >
      <mat-card-content>
				 <input type="hidden" formControlName="orgID">
				<mat-horizontal-stepper #stepper>
					<mat-step>
						<ng-template matStepLabel>
							<span [ngClass]="{ error: campaignInfoTabValid() }">Campaign Info</span>
						</ng-template>
						<div class="form-field-row admin-select-field">
							<mat-form-field >
								<input matInput type="text" formControlName="campaignName" placeholder="Campaign Name" />
							</mat-form-field>
							<mat-form-field *ngIf="users">
								<mat-select formControlName="adminUserID" placeholder="Campaign Admin">
									<mat-option *ngFor="let u of users" [value]="u.userID">
										{{u.firstName}} {{u.lastName}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="form-field-row">
							<mat-form-field>
								<mat-select formControlName="maxTypesToSend" placeholder="Max Communications To Send">
									<mat-option *ngFor="let i of maxTypesToSendList" [value]="i">{{i}}</mat-option>
								</mat-select>
							</mat-form-field>
							<div class="spacer">&nbsp;</div>
						</div>
						<div class="priority-container">
							<div class="priority-title" >Choose priority</div>
							<div cdkDropList class="priority-list" (cdkDropListDropped)="priorityListDrop($event)">
								<div class="priority-box" *ngFor="let comm of communicationWays" cdkDrag>{{comm}}</div>
							</div>
						</div>
						<mat-form-field >
							<mat-datetimepicker-toggle [for]="datetimePicker" matSuffix>
								<mat-icon matDatepickerToggleIcon ><i class="fa fa-calendar-alt"></i></mat-icon>
							</mat-datetimepicker-toggle>
							<mat-datetimepicker #datetimePicker type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
							<input matInput formControlName="deliveryDateTime" [matDatetimepicker]="datetimePicker" autocomplete="false" placeholder="Scheduled Delivery Date">
						</mat-form-field>
						<mat-form-field >
							<textarea matInput placeholder="Description"  formControlName="campaignDesc" rows="10" > </textarea>
						</mat-form-field>
					</mat-step>

					<mat-step>
						<ng-template matStepLabel>
							<span [ngClass]="{ error: listFilterTabValid() }">List and Filters</span>
						</ng-template>
						<div class="form-field-row">
							<mat-form-field class="half-width">
								<mat-select formControlName="listID" placeholder="Select your List">
									<mat-option *ngFor="let l of lists" [value]="l.listID">
										<span *ngIf="l.groupName">{{l.groupName}} - </span>{{l.listName}} 
									</mat-option>
								</mat-select>
							</mat-form-field>
							<button mat-raised-button color="primary" (click)="previewList($event)"  *ngIf="!!campaignForm.get('listID').value"><span>Preview List</span></button>
							<div class="spacer">&nbsp;</div>
						</div>
						<section class="filter-section">
							<h4 class="filter-type-title" >Filter Type</h4>
							<mat-button-toggle-group formControlName="filterTypeID" >
								<mat-button-toggle value="1">AND</mat-button-toggle>
								<mat-button-toggle value="2">OR</mat-button-toggle>
							</mat-button-toggle-group>
							<app-list-params [filters]='filters' #listParams></app-list-params>
						</section>
					</mat-step>

					<mat-step>
						<ng-template matStepLabel>
							<span [ngClass]="{ error: !isEmailValid() }">Email</span>
						</ng-template>
						<mat-slide-toggle formControlName="email" (change)="toggleValue('email', $event)">Active</mat-slide-toggle>
						<div class="form-field-row">
							<mat-form-field>
								<mat-select formControlName="emailSourceID" placeholder="Select source">
									<mat-option *ngFor="let s of emailSources" [value]="s.sourceID">
										{{s.source}} 
									</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-form-field >
								<input matInput type="text" formControlName="emailSubject" placeholder="Subject" />
							</mat-form-field>
						</div>
						
						<div class="form-field-row" *ngIf="templates">
							<mat-form-field >
								<mat-label>Select Template</mat-label>
								<mat-select (selectionChange)="loadTemplate($event.value)">
									<mat-option value=""></mat-option>
									<mat-option *ngFor="let template of templates" [value]="template.templateID">{{template.name}}</mat-option>
								</mat-select>
							</mat-form-field>
							<div class="spacer">&nbsp;</div>
						</div>
						<email-editor (loaded)="editorLoaded($event)" [options]="emailEditorOptions"></email-editor>

						<div class="attachments">
							<p>Attached Files</p>
							<ul *ngIf="campaign.EmailMediaAttachments.length > 0; else noEmailAttachments">
								<li *ngFor="let att of campaign.EmailMediaAttachments">
									{{att.mediaDesc}} <i class="fa fa-trash" (click)="removeMediaFile('email', att.mediaID)"></i>
								</li>
							</ul>
							<ng-template #noEmailAttachments>None</ng-template>
						</div>
						<button mat-stroked-button color="primary" (click)="attachFile('email',$event)">Attach file from Library</button>
					</mat-step>

					<mat-step label="SMS">
						<ng-template matStepLabel>
							<span [ngClass]="{ error: !isSmsValid() }">SMS</span>
						</ng-template>
						<mat-slide-toggle formControlName="sms" (change)="toggleValue('sms', $event)">Active</mat-slide-toggle>
						<div class="form-field-row">
							<mat-form-field>
								<mat-select formControlName="smsSourceID" placeholder="Select source">
									<mat-option *ngFor="let s of smsSources" [value]="s.sourceID">
										{{s.source | phone}} 
									</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-form-field >
								<textarea class="sms-text-area" matInput placeholder="SMS Text"  formControlName="smsText" (keyup)="checkSmsForUrl()"> </textarea>
								<mat-hint *ngIf="badSmsUrlPlacement" class="error">For best results, any links should be at the very beginning or very end of the text.</mat-hint>
							</mat-form-field>
						</div>
						<div class="attachments">
							<p>Attached Files</p>
							<ul *ngIf="campaign.SMSMediaAttachments.length > 0; else noSmsAttachments">
								<li *ngFor="let att of campaign.SMSMediaAttachments">
									{{att.mediaDesc}} <i class="fa fa-trash" (click)="removeMediaFile('sms', att.mediaID)"></i>
								</li>
							</ul>
							<ng-template #noSmsAttachments>None</ng-template>
						</div>
						<button mat-stroked-button color="primary" (click)="attachFile('sms',$event)">Attach file from Library</button>
					</mat-step>

					<mat-step label="Phone">
						<ng-template matStepLabel>
							<span [ngClass]="{ error: !isPhoneValid() }">Phone</span>
						</ng-template>
						<mat-slide-toggle formControlName="phone" (change)="toggleValue('phone', $event)">Active</mat-slide-toggle>
						<mat-form-field>
							<mat-select formControlName="phoneSourceID" placeholder="Select source">
								<mat-option *ngFor="let s of phoneSources" [value]="s.sourceID">
									{{s.source}} 
								</mat-option>
							</mat-select>
						</mat-form-field>
						<section class="file-section">
							<h4 class="filter-type-title" >.WAV File</h4>
							<audio controls src="{{campaign.phoneWavFilePath}}" type="audio/wav" *ngIf="this.campaignForm.value.phoneWavFileName">
							</audio>
							<div class="btn red btn-upload-wav" *ngIf="wavPresent" (click)="removeFile()"><i class="fa fa-minus-circle"></i><span>Remove .WAV File</span></div>
							<div class="fileUploadButton">
								<div class="btn dark btn-upload-wav"><i class="fa fa-upload"></i><span>{{wavPresent ? 'Update' : 'Upload'}} .WAV File</span></div>
								<input type="file" #fileInput (change)="onFileChange($event)" accept=".wav">
								<div class="fileToUploadName" *ngIf="wavMessage">{{wavMessage}}</div>
							</div>
						</section>

					</mat-step>
				</mat-horizontal-stepper>

              
         
	  </mat-card-content>
	</mat-card>
	<mat-card-actions class="page-actions">
		<section class="submit-section">
			<button mat-raised-button class="submit-button" color="primary" type="submit" >Save</button>
			<button mat-raised-button class="execute-button" color="primary" type="button" *ngIf="isEdit" (click)="executeCampaign()" >Execute Program Now</button>
			<button mat-raised-button class="test-button" color="primary" type="button" *ngIf="isEdit" (click)="showTestDialog()" >Test</button>
		</section>
	</mat-card-actions>
	</form>
</div>
