import { ICommunicationState, initialCommunicationState } from '../state/communication.state';
import { CommunicationActions, CommunicationActionTypes } from '../actions/communication.actions';
import { Action } from '@ngxs/store';


export function communicationReducer(state: ICommunicationState = initialCommunicationState, action: CommunicationActions): ICommunicationState {
	switch(action.type) {
		case CommunicationActionTypes.SET_TITLE: 
			return { ...state, title: action.title };

		case CommunicationActionTypes.GET_LISTS:
			return state;
		case CommunicationActionTypes.GET_LISTS_SUCCESS:
			return { ...state, lists: action.lists };
		case CommunicationActionTypes.GET_LIST:
			return state;
		case CommunicationActionTypes.GET_LIST_SUCCESS:
			return { ...state, list: action.list };
		case CommunicationActionTypes.NEW_LIST: 
			return state;
		case CommunicationActionTypes.NEW_LIST_SUCCESS: 
			return { ...state, lists: [ ...state.lists, action.list]}
		case CommunicationActionTypes.DELETE_LIST:
			return { ...state, listIdToDelete: action.listID };
		case CommunicationActionTypes.DELETE_LIST_CANCEL:
			return { ...state, listIdToDelete: null };
		case CommunicationActionTypes.DELETE_LIST_CONFIRM:
			return state;
		case CommunicationActionTypes.DELETE_LIST_SUCCESS: 
			return { ...state, listIdToDelete: null, lists: state.lists.filter(l => l.listID != state.listIdToDelete) };
			
		case CommunicationActionTypes.SAVE_LIST:
			return state;
		case CommunicationActionTypes.SAVE_LIST_CONFIRM:
			return { ...state, list: action.list};
		case CommunicationActionTypes.SAVE_LIST_SUCCESS:
			return { ...state, lists: state.lists.map(l => l.listID == state.list.listID ? state.list : l) };

		case CommunicationActionTypes.SET_ACTIVE_CAMPAIGN:
			return { ...state, activeCampaignId: action.campaignId};
		case CommunicationActionTypes.SET_ACTIVE_GROUP:
			return { ...state, activeGroupId: action.groupId };
		case CommunicationActionTypes.ACTIVATE_CAMPAIGN:
			return { ...state, activeCampaignId: action.campaignId};
		case CommunicationActionTypes.ACTIVATE_CAMPAIGN_SUCCESS:
			return { ...state, activeCampaign: action.campaign };
		case CommunicationActionTypes.EDIT_CAMPAIGN_CANCEL: 
			return { ...state, activeCampaign: null, activeCampaignId: null };
		case CommunicationActionTypes.EDIT_CAMPAIGN_CONFIRM: 
			return state;
		case CommunicationActionTypes.EDIT_CAMPAIGN_SUCCESS:
			return { ...state, activeCampaignId: '', activeCampaign: null, campaigns: state.campaigns.map(c => c.campaignID == state.activeCampaignId ? action.campaign : c) };
		case CommunicationActionTypes.DELETE_CAMPAIGN:
			return { ...state, campaignIdToDelete: action.campaignID};
		case CommunicationActionTypes.DELETE_CAMPAIGN_CANCEL:
			return { ...state, campaignIdToDelete: null };
		case CommunicationActionTypes.DELETE_CAMPAIGN_CONFIRM:
			return state;
		case CommunicationActionTypes.DELETE_CAMPAIGN_SUCCESS: 
			return { ...state, campaignIdToDelete: null, campaigns: state.campaigns.filter(c => c.campaignID != state.campaignIdToDelete) };
		case CommunicationActionTypes.RUN_REPORT: 
			return state;
		case CommunicationActionTypes.RUN_REPORT_CONFIRM: 
			return { ...state, activeCampaignId: action.reportData.campaignID, reportData: action.reportData };	
		case CommunicationActionTypes.RUN_REPORT_SUCCESS:
			return { ...state, reportData: null, reportResults: action.results };
		case CommunicationActionTypes.CLEAR_REPORT:
			return { ...state, reportResults: null };
		case CommunicationActionTypes.GET_GROUPS: 
			return state;
		case CommunicationActionTypes.GET_GROUPS_SUCCESS: 
			return { ...state, groups: action.groups };
		case CommunicationActionTypes.ADD_GROUP:
			return { ...state, group: action.group };
		case CommunicationActionTypes.ADD_GROUP_SUCCESS:
			return { ...state, groups: [ ...state.groups, { ...state.group, groupID: action.groupId }], group: null };
		case CommunicationActionTypes.EDIT_GROUP: 
			return { ...state, group: action.group };
		case CommunicationActionTypes.EDIT_GROUP_CONFIRM:
			return state;
		case CommunicationActionTypes.EDIT_GROUP_CANCEL:
			return { ...state, group: null}
		case CommunicationActionTypes.EDIT_GROUP_SUCCESS:
			return { ...state, groups: state.groups.map(g => g.groupID == state.group.groupID ? state.group : g), group: null };
		case CommunicationActionTypes.DELETE_GROUP: 
			return { ...state, groupToDelete: action.group };
		case CommunicationActionTypes.DELETE_GROUP_CANCEL:
			return { ...state, groupToDelete: null }
		case CommunicationActionTypes.DELETE_GROUP_CONFIRM:
			return state;
		case CommunicationActionTypes.DELETE_GROUP_SUCCESS:
			return { ...state, groups: state.groups.filter(g => g.groupID !== state.groupToDelete.groupID), groupToDelete: null };
		case CommunicationActionTypes.RESET:
			return initialCommunicationState;
		case CommunicationActionTypes.SET_REDUCED_GROUPS:
			return { ...state, reducedGroups: action.reducedGroups };
		case CommunicationActionTypes.SET_CAMPAIGN_SEARCH_TERM:
			return { ...state, campaignSearchTerm: action.term };
		default:
			return state;
	}
}