import { Action } from '@ngrx/store';
import { CommList, CommCampaign, CommCampaignDetail, CommGroup } from 'acc-models';


export enum CommunicationActionTypes {
	SET_TITLE = '[COMMUNICATION] Set Title',
	
	GET_LISTS = '[COMMUNICATION] Get Lists',
	GET_LISTS_SUCCESS = '[COMMUNICATION] Get Lists Success',
	GET_LIST = '[COMMUNICATION] Get List',
	GET_LIST_SUCCESS = '[COMMUNICATION] Get List Success',
	SAVE_LIST = '[COMMUNICATION] Save List',
	SAVE_LIST_CONFIRM = '[COMMUNICATION] Save List Confirm',
	SAVE_LIST_SUCCESS = '[COMMUNICATION] Save List Success',
	NEW_LIST = '[COMMUNICATION] New List',
	NEW_LIST_SUCCESS = '[COMMUNICATION] New List Success',
	
	DELETE_LIST = '[COMMUNICATION] Delete List',
	DELETE_LIST_CONFIRM = '[COMMUNICATION] Delete List Confirm',
	DELETE_LIST_CANCEL = '[COMMUNICATION] Delete List Cancel',
	DELETE_LIST_SUCCESS = '[COMMUNICATION] Delete List Success',
		
	GET_CAMPAIGNS = '[COMMUNICATION] Get Campaigns',
	GET_CAMPAIGNS_SUCCESS = '[COMMUNICATION] Get Campaigns Success',
	SET_ACTIVE_CAMPAIGN = '[COMMUNICATION] Set Active Campaign',
	SET_ACTIVE_GROUP = '[COMMUNICATION] Set Active Group',
	ACTIVATE_CAMPAIGN = '[COMMUNICATION] Activate Campaign',
	ACTIVATE_CAMPAIGN_SUCCESS = '[COMMUNICATION] Activate Campaign Success',
	EDIT_CAMPAIGN_CONFIRM = '[COMMUNICATION] Edit Campaign Confirm',
	EDIT_CAMPAIGN_CANCEL = '[COMMUNICATION] Edit Campaign Cancel',
	EDIT_CAMPAIGN_SUCCESS = '[COMMUNICATION] Edit Campaign Success',
	
	DELETE_CAMPAIGN  = '[COMMUNICATION] Delete Campaign ',	
	DELETE_CAMPAIGN_CONFIRM = '[COMMUNICATION] Delete Campaign Confirm',	
	DELETE_CAMPAIGN_CANCEL = '[COMMUNICATION] Delete Campaign Cancel',	
	DELETE_CAMPAIGN_SUCCESS = '[COMMUNICATION] Delete Campaign Success',	

	RUN_REPORT = '[COMMUNICATION] Run Report',
	RUN_REPORT_CONFIRM = '[COMMUNICATION] Run Report Confirm',
	RUN_REPORT_SUCCESS = '[COMMUNICATION] Run Report Success',
	CLEAR_REPORT = '[COMMUNICATION] Clear Report',

	GET_GROUPS = '[COMMUNICATION] Get Groups',
	GET_GROUPS_SUCCESS = '[COMMUNICATION] Get Groups Success',
	ADD_GROUP = '[COMMUNICATION] Add Group',
	ADD_GROUP_SUCCESS = '[COMMUNICATION] Add Group Success',
	EDIT_GROUP = '[COMMUNICATION] Edit Group',
	EDIT_GROUP_CONFIRM = '[COMMUNICATION] Edit Group Confmri',
	EDIT_GROUP_CANCEL = '[COMMUNICATION] Edit Group Cancel',
	EDIT_GROUP_SUCCESS = '[COMMUNICATION] Edit Group Success',
	DELETE_GROUP  = '[COMMUNICATION] Delete Group ',	
	DELETE_GROUP_CONFIRM = '[COMMUNICATION] Delete Group Confirm',	
	DELETE_GROUP_CANCEL = '[COMMUNICATION] Delete Group Cancel',	
	DELETE_GROUP_SUCCESS = '[COMMUNICATION] Delete Group Success',	

	SET_REDUCED_GROUPS = '[COMMUNICATION] Set Reduced Groups',
	SET_CAMPAIGN_SEARCH_TERM = '[COMMUNICATIONS] Set Search Term',

	RESET = '[ROOT] Reset'
	
}

export class SetTitle implements Action {
	public readonly type = CommunicationActionTypes.SET_TITLE;
	constructor(public title: string) {}
}

export class GetLists implements Action {
	public readonly type = CommunicationActionTypes.GET_LISTS;
	constructor(public orgId: number) {}
}
export class GetListsSuccess implements Action {
	public readonly type = CommunicationActionTypes.GET_LISTS_SUCCESS;
	constructor(public lists: Array<CommList>) {}
}
export class GetList implements Action {
	public readonly type = CommunicationActionTypes.GET_LIST;
	constructor(public listId: string) {}
}
export class GetListSuccess implements Action {
	public readonly type = CommunicationActionTypes.GET_LIST_SUCCESS;
	constructor(public list: CommList) {}
}
export class SaveList implements Action {
	public readonly type = CommunicationActionTypes.SAVE_LIST;
	constructor(){}
}
export class SaveListConfirm implements Action {
	public readonly type = CommunicationActionTypes.SAVE_LIST_CONFIRM;
	constructor(public list: CommList){}
}
export class SaveListSuccess implements Action {
	public readonly type = CommunicationActionTypes.SAVE_LIST_SUCCESS;
	constructor(public list: CommList){}
}
export class NewList implements Action {
	public readonly type = CommunicationActionTypes.NEW_LIST;
	constructor(public list: CommList) {}
}
export class NewListSuccess implements Action {
	public readonly type = CommunicationActionTypes.NEW_LIST_SUCCESS;
	constructor(public list: CommList) {}
}

export class DeleteList implements Action {
	public readonly type = CommunicationActionTypes.DELETE_LIST;
	constructor(public listID: string) {}
}
export class DeleteListConfirm implements Action {
	public readonly type = CommunicationActionTypes.DELETE_LIST_CONFIRM;
	constructor() {}
}
export class DeleteListCancel implements Action {
	public readonly type = CommunicationActionTypes.DELETE_LIST_CANCEL;
	constructor() {}
}
export class DeleteListSuccess implements Action {
	public readonly type = CommunicationActionTypes.DELETE_LIST_SUCCESS;
	constructor() {}
}

export class SetActiveCampaign implements Action {
	public readonly type = CommunicationActionTypes.SET_ACTIVE_CAMPAIGN;
	constructor(public campaignId: string) {}
}export class SetActiveGroup implements Action {
	public readonly type = CommunicationActionTypes.SET_ACTIVE_GROUP;
	constructor(public groupId: number) {}
}
export class ActivateCampaign implements Action {
	public readonly type = CommunicationActionTypes.ACTIVATE_CAMPAIGN;
	constructor(public campaignId: string) {}
}
export class ActivateCampaignSuccess implements Action {
	public readonly type = CommunicationActionTypes.ACTIVATE_CAMPAIGN_SUCCESS;
	constructor(public campaign: CommCampaign) {}
}
export class EditCampaignConfirm implements Action {
	public readonly type = CommunicationActionTypes.EDIT_CAMPAIGN_CONFIRM;
	constructor(public campaign: CommCampaign) {}
}
export class EditCampaignCancel implements Action {
	public readonly type = CommunicationActionTypes.EDIT_CAMPAIGN_CANCEL;
	constructor() {}
}
export class EditCampaignSuccess implements Action {
	public readonly type = CommunicationActionTypes.EDIT_CAMPAIGN_SUCCESS;
	constructor(public campaign:CommCampaign) {}
}
export class DeleteCampaign implements Action {
	public readonly type = CommunicationActionTypes.DELETE_CAMPAIGN;
	constructor(public campaignID: string) {}
}
export class DeleteCampaignConfirm implements Action {
	public readonly type = CommunicationActionTypes.DELETE_CAMPAIGN_CONFIRM;
	constructor() {}
}
export class DeleteCampaignCancel implements Action {
	public readonly type = CommunicationActionTypes.DELETE_CAMPAIGN_CANCEL;
	constructor() {}
}
export class DeleteCampaignSuccess implements Action {
	public readonly type = CommunicationActionTypes.DELETE_CAMPAIGN_SUCCESS;
	constructor() {}
}

export class RunReport implements Action {
	public readonly type = CommunicationActionTypes.RUN_REPORT;
	constructor() {}
}
export class RunReportConfirm implements Action {
	public readonly type = CommunicationActionTypes.RUN_REPORT_CONFIRM;
	constructor(public reportData: {campaignID?: string, groupID?: number, lastName: string, phoneNumber: string, email: string, communicationType: string, statusCode: string }) {}
}
export class RunReportSuccess implements Action {
	public readonly type = CommunicationActionTypes.RUN_REPORT_SUCCESS;
	constructor(public results: CommCampaignDetail) {}
}
export class ClearReport implements Action {
	public readonly type = CommunicationActionTypes.CLEAR_REPORT;
	constructor() {}
}
export class CommunicationReset implements Action {
	public readonly type = CommunicationActionTypes.RESET;
	constructor() {}
}

export class GetGroups implements Action {
	public readonly type = CommunicationActionTypes.GET_GROUPS;
	constructor(public orgId: number) {}
}
export class GetGroupsSuccess implements Action {
	public readonly type = CommunicationActionTypes.GET_GROUPS_SUCCESS;
	constructor(public groups: Array<CommGroup>) {}
}

export class EditGroup implements Action {
	public readonly type = CommunicationActionTypes.EDIT_GROUP;
	constructor(public group: CommGroup){}
}
export class EditGroupConfirm implements Action {
	public readonly type = CommunicationActionTypes.EDIT_GROUP_CONFIRM;
	constructor(){}
}export class EditGroupCancel implements Action {
	public readonly type = CommunicationActionTypes.EDIT_GROUP_CANCEL;
	constructor(){}
}export class EditGroupSuccess implements Action {
	public readonly type = CommunicationActionTypes.EDIT_GROUP_SUCCESS;
	constructor(){}
}
export class AddGroup implements Action {
	public readonly type = CommunicationActionTypes.ADD_GROUP;
	constructor(public group: CommGroup) {}
}
export class AddGroupSuccess implements Action {
	public readonly type = CommunicationActionTypes.ADD_GROUP_SUCCESS;
	constructor(public groupId: number) {}
}
export class DeleteGroup implements Action {
	public readonly type = CommunicationActionTypes.DELETE_GROUP;
	constructor(public group: CommGroup) {}
}
export class DeleteGroupConfirm implements Action {
	public readonly type = CommunicationActionTypes.DELETE_GROUP_CONFIRM;
	constructor() {}
}
export class DeleteGroupCancel implements Action {
	public readonly type = CommunicationActionTypes.DELETE_GROUP_CANCEL;
	constructor() {}
}
export class DeleteGroupSuccess implements Action {
	public readonly type = CommunicationActionTypes.DELETE_GROUP_SUCCESS;
	constructor() {}
}
export class SetReducedGroups implements Action {
	public readonly type = CommunicationActionTypes.SET_REDUCED_GROUPS;
	constructor(public reducedGroups: Array<CommGroup>) {}
}
export class SetCampaignSearchTerm implements Action {
	public readonly type = CommunicationActionTypes.SET_CAMPAIGN_SEARCH_TERM;
	constructor(public term: string) {}
}


export type CommunicationActions = 
	SetTitle |
	GetLists | 
	GetListsSuccess |
	GetList | 
	GetListSuccess |
	NewList |
	NewListSuccess |
	SaveList |
	SaveListConfirm |
	SaveListSuccess |
	DeleteList |
	DeleteListCancel |
	DeleteListConfirm |
	DeleteListSuccess |
	SetActiveCampaign |
	SetActiveGroup |
	ActivateCampaign |
	ActivateCampaignSuccess |
	EditCampaignCancel |
	EditCampaignConfirm |
	EditCampaignSuccess |
	DeleteCampaign |
	DeleteCampaignCancel |
	DeleteCampaignConfirm |
	DeleteCampaignSuccess |
	RunReport |
	RunReportConfirm |
	RunReportSuccess |
	ClearReport |
	CommunicationReset |
	GetGroups | 
	GetGroupsSuccess | 
	AddGroup |
	AddGroupSuccess |
	EditGroup | 
	EditGroupConfirm |
	EditGroupCancel |
	EditGroupSuccess | 
	DeleteGroup | 
	DeleteGroupCancel | 
	DeleteGroupConfirm |
	DeleteGroupSuccess |
	SetReducedGroups |
	SetCampaignSearchTerm 
	;