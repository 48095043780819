import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataService, SessionService } from 'acc-services';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommCampaign, Button, User } from 'acc-models';
import { Subject, SubscriptionLike } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import * as CommunicationActions from '../../store/actions/communication.actions';
import { ConfirmComponent } from 'acc-common';
import { take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { selectCampaignReducedGroups, selectCampaignSearchTerm } from '../../store/selectors/communication.selectors';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit, OnDestroy {

 	public user: User;
 	public campaigns: Array<CommCampaign>;
	private getCampaignsSubscription:SubscriptionLike;
	private copyCampaignSubscription:SubscriptionLike;
	displayedColumns: string[] = ['campaignName', 'campaignType', 'deliveryDateTime', 'campaignStatusDesc', 'campaignActions'];
	public confirmCopyButtons: Array<Button> = new Array<Button>();
	public confirmCopyItemMessage: string = 'Are you sure you want to copy the campaign?';
	public showCopyCampaign: boolean = false;
	private selectedCampaign:string;

	private rawData: Array<any>;
	private reducedGroups: Array<any> = [];
	public filterValue: string;

	private initialLoad: boolean = true;
	private destroy$: Subject<any> = new Subject();

	dataSource: Array<any>;

	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		private sessionService: SessionService, 
		private dataService: DataService,
		public dialog: MatDialog,
		private router: Router,
		private snackBar: MatSnackBar,
		private store: Store<IAppState>
		) { 
		this.user = this.sessionService.getUser();
		this.confirmCopyButtons.push(new Button("Yes", ""));
		this.confirmCopyButtons.push(new Button("No", ""));
	}

	ngOnInit() {
		this.getCampaigns();
		this.store.dispatch(new CommunicationActions.SetTitle('Campaigns'));
		this.store.pipe(
			select(selectCampaignSearchTerm),
			takeUntil(this.destroy$),
		).subscribe(term => {
			if(term) {
				this.filterValue = term;
				this.buildDataSource();
			}
		})
	}

	applyFilter() {
		this.store.dispatch(new CommunicationActions.SetCampaignSearchTerm(this.filterValue));
		this.buildDataSource();
	}

	getCampaigns() {
		this.getCampaignsSubscription = this.dataService.getCampaigns(this.user.orgID).subscribe((lists:any) => {
			this.campaigns = lists.map(l => new CommCampaign(l)).sort((a,b) => {
				if(a.listName < b.listName) { return 1; }
				if(a.listName > b.listName) { return -1; }
				return 0;
			});
			this.rawData = lists.map(l => new CommCampaign({ ...l, deliveryDateTime: l.deliveryDateTime ? moment.utc(l.deliveryDateTime) : null }));
			if(this.initialLoad) {
				this.reducedGroups = this.rawData.map(d => { return { groupName: d.groupName || "(No Group)", value: d.groupName, isGroup: true, reduced: true } }).filter((value, index, self) => self.indexOf(value) === index);
			}
			
			this.store.pipe(
				select(selectCampaignReducedGroups),
				takeUntil(this.destroy$)
			).subscribe(grps => {
				if(grps != null) {
					this.reducedGroups = grps;
				}
			})
			
	
			this.dataSource = this.groupBy();
		});
	}

	buildDataSource() {
		this.dataSource = this.groupBy();
	}
	groupBy() {
		let collapsedGroups = this.reducedGroups || [];
		const reducer = (acc, val) => {
			let currentGroup = val['groupName'];
			if(!acc[currentGroup]) {
				acc[currentGroup] = [{
					groupName: currentGroup || "(No Group)",
					value: currentGroup,
					isGroup: true,
					reduced: collapsedGroups.some(group => group.value == val['groupName'])
				}];
			}
			acc[currentGroup].push(val);
			return acc;
		}
		let groups = this.rawData.reduce(reducer, []);
		let groupArray = Object.keys(groups).map(key => groups[key]);

		if(this.sort && this.sort.active) {
			const sortFn = (a,b) => {
				const field = this.sort.active;
				if(this.sort.direction == 'asc') {
					if(a[field] > b[field]) return 1;
					if(a[field] < b[field]) return -1;
					return 0;
				} else {
					if(a[field] > b[field]) return -1;
					if(a[field] < b[field]) return 1;
					return 0;
				}
			}
			groupArray.forEach(g => {
				g = g.sort(sortFn)
			})
		}


		if(this.filterValue) {
			groupArray = groupArray.map(arr => {
				if(arr[0].groupName.toLowerCase().includes(this.filterValue.toLocaleLowerCase())) {
					return arr;
				}
				return [ ...arr.filter(g => g.isGroup || (g.campaignName || '').toLowerCase().includes(this.filterValue.toLowerCase()))]
			})
	
			groupArray = groupArray.filter(a => a.length > 1);
		}

		groupArray = groupArray.sort((a,b) => {
			if(a[0].value == null && b[0].value != null) return 1;
			if(a[0].value != null && b[0].value == null) return -1;
			if(a[0].groupName > b[0].groupName) return 1;
			if(a[0].groupName < b[0].groupName) return -1;
			return 0;
		})
		
		let flatList = groupArray.reduce((a,c) => { return a.concat(c); }, []);

		return flatList.filter(rawline => {
			return rawline.isGroup || collapsedGroups.every(group => rawline['groupName'] != group.value);
		})
	}

	reduceGroup(row) {
		row.reduced = !row.reduced;
		if(row.reduced) {
			this.reducedGroups.push(row);
		} else {
			this.reducedGroups = this.reducedGroups.filter(g => g.value != row.value);
		}
		this.store.dispatch(new CommunicationActions.SetReducedGroups(this.reducedGroups));
		this.initialLoad = false;
		this.buildDataSource()
	}
	isGroup(index, item): boolean {
		return item.isGroup;
	}

	resort() {
		this.buildDataSource();
	}
  	showInfo(camp){
	  	this.store.dispatch(new CommunicationActions.ActivateCampaign(camp.campaignID));
    	this.router.navigate(['/communication/campaigns/campaign-edit', camp.campaignID]);
  	}
	
	deleteCampaign(campaign): void {
		this.store.dispatch(new CommunicationActions.DeleteCampaign(campaign.campaignID));
		const deleteDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: 'Confirm',
				message: `Are you sure you want to delete the campaign <b>${campaign.campaignName}</b>?`
			}
		});
		deleteDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
			if(confirm) {
				this.store.dispatch(new CommunicationActions.DeleteCampaignConfirm());
				this.rawData = this.rawData.filter(c => c.campaignID != campaign.campaignID);
				this.dataSource = this.groupBy();
			} else {
				this.store.dispatch(new CommunicationActions.DeleteCampaignCancel());
			}
		});
	}


	copyCampaign(id){
		this.selectedCampaign = id;
		let copyCampaignDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				message: 'Are you sure you want to copy the campaign?',
				title: 'Confirm'
			}
		});
		copyCampaignDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
			if(confirm) {
				this.copyCampaignSubscription = this.dataService.copyCampaign(this.selectedCampaign).subscribe((copied:any) => {
					let snackBarRef = this.snackBar.open('Campaign Copied!', 'Close', {
					duration: 3000
					});
					this.getCampaigns();
				});
			}
		})
  	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
