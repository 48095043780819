import { Component, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { IAppState } from "../../store/state/app.state";
import * as CommunicationSelectors from "../../store/selectors/communication.selectors";
import { CommGroup } from "acc-models";
import { map, take, takeUntil, tap } from "rxjs/operators";
import { AddGroup, CommunicationActionTypes, DeleteGroup, DeleteGroupCancel, DeleteGroupConfirm, EditGroup, EditGroupCancel, EditGroupConfirm, GetGroups } from "../../store/actions/communication.actions";
import { SessionService } from "acc-services";
import { Actions } from "@ngrx/effects";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmComponent } from "acc-common";

@Component({
	selector: "acc-main-groups",
	templateUrl: "./groups.component.html",
	styleUrls: ["./groups.component.scss"],
})
export class GroupsComponent implements OnInit, OnDestroy {
	private orgId: number;
	public newGroupName: string;
	private origGroupName: string;
	public activeGroup: CommGroup;
	public groups: Array<CommGroup>;
	private destroy$: Subject<boolean> = new Subject();
	public groups$ = this.store.pipe(
		select(CommunicationSelectors.selectGroups),
		tap((g) => (this.groups = g))
	);
	public group$ = this.store
		.pipe(
			select(CommunicationSelectors.selectGroup),
			tap((g) => (this.activeGroup = g)),
			takeUntil(this.destroy$)
		)
		.subscribe();
	constructor(private store: Store<IAppState>, private actions$: Actions, private sessionService: SessionService, private dialog: MatDialog) {
		actions$
			.pipe(
				map((a) => {
					switch (a.type) {
						case CommunicationActionTypes.ADD_GROUP_SUCCESS:
							this.newGroupName = "";
							break;
					}
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	ngOnInit() {
		this.orgId = this.sessionService.getUser().orgID;
		this.store.dispatch(new GetGroups(this.orgId));
	}

	selectGroupToEdit(group: CommGroup) {
		this.origGroupName = group.groupName;
		this.store.dispatch(new EditGroup(group));
	}

	deleteGroup(group: CommGroup) {
		this.store.dispatch(new DeleteGroup(group));
		const confirmDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: 'Confirm',
				message: `<p>Are you sure you want to delete the group <b>${group.groupName}?`
			}
		});
		confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
			if(confirm) {
				this.store.dispatch(new DeleteGroupConfirm());
			} else {
				this.store.dispatch(new DeleteGroupCancel());
			}
		})
	}

	saveGroupEdit(group: CommGroup) {
		this.store.dispatch(new EditGroupConfirm());
	}

	cancelGroupEdit(group: CommGroup) {
		group.groupName = this.origGroupName;
		this.store.dispatch(new EditGroupCancel());
	}

	addGroup(group: CommGroup) {
		if(!this.newGroupName) return;
		this.store.dispatch(new AddGroup(new CommGroup({ orgId: this.orgId, groupName: this.newGroupName })));
	}

	ngOnDestroy() {
		this.destroy$.next(false);
		this.destroy$.complete();
	}
}
