

<div *ngIf="groups$ | async">
	<div class="grid">
		<div class="col">
			<div class="header row">
				<div class="description">Group Name</div>
			</div>
			<ul class="row">
				<li class="item" *ngFor="let group of groups" [ngClass]="{ active: group.groupID === activeGroup?.groupID }">
					<div class="description">
						<span *ngIf="activeGroup?.groupID != group.groupID">{{ group.groupName }}</span>
						<input type="text" [(ngModel)]="group.groupName" class="edit-group-name" *ngIf="activeGroup?.groupID == group.groupID" (keyup.enter)="saveGroupEdit(group)" />
					</div> 
					<div class="actions">
						<ng-container *ngIf="activeGroup?.groupID != group.groupID">
							<span class="action edit" (click)="selectGroupToEdit(group)" ><mat-icon>mode_edit</mat-icon></span>
							<span class="action delete" (click)="deleteGroup(group)"><mat-icon>delete</mat-icon></span>
						</ng-container>
						<ng-container  *ngIf="activeGroup?.groupID == group.groupID">
							<span class="action save" (click)="saveGroupEdit(group)"><mat-icon>save</mat-icon></span>
							<span class="action cancel" (click)="cancelGroupEdit(group)"><mat-icon>cancel_presentation</mat-icon></span>
						</ng-container>
					</div>
				</li>
				<li class="item">
					<div class="description">
						<input type="text" [(ngModel)]="newGroupName" (keyup.enter)="addGroup()" />
					</div>
					<div class="actions">
						<span class="addGroup" (click)="addGroup()"><i class="fa fa-save"></i></span>
					</div>
				</li>
			</ul>
		</div>
	</div>
</div>

