import { CommListData } from './commListData';

export class CommList {
	public listID : string;
	public createDateTime: Date;
	public modifyDateTime: Date;
	public totalRecords: number;
	public totalPages: number;
	public CommListDatas: Array<CommListData>;
	public orgID: number;
	public listName: string;
	public listDesc: string;
	public usedInExecutedCampaign: boolean;
	public groupID: number;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}
