import { CommList, CommCampaign, CommCampaignDetail } from 'acc-models';
import { CommGroup } from 'projects/acc-models/src/lib/models/commGroup';

export interface ICommunicationState {
	title: string,
	lists: Array<CommList>,
	list: CommList,
	listIdToDelete: string,
	campaigns: Array<CommCampaign>,
	activeCampaignId: string;
	activeCampaign: CommCampaign,
	activeGroupId: number,
	campaignIdToDelete: string,
	reportData:  { lastName: string, phoneNumber: string, email: string, communicationType: string },
	reportResults: CommCampaignDetail,
	groups: Array<CommGroup>,
	group: CommGroup,
	groupToDelete:CommGroup,
	reducedGroups: Array<CommGroup>,
	campaignSearchTerm: string;
}

export const initialCommunicationState: ICommunicationState = {
	title: '',
	lists: [],
	list: null,
	listIdToDelete: '',
	campaigns: [],
	activeCampaignId: '',
	activeCampaign: null,
	activeGroupId: null,
	campaignIdToDelete: '',
	reportData:  { lastName: '', phoneNumber: '', email: '', communicationType: '' },
	reportResults: null,
	groups: null,
	group: null,
	groupToDelete: null,
	reducedGroups: null,
	campaignSearchTerm: '',
}